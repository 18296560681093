import { defineStore } from 'pinia';
import { capitalize } from '#root/shared/utils/types/string-utils';
import routeConfig from '~/config/route-config';
import { getContentCardConfig } from '@brand/config/content-card-config';

const DEFAULT_PAGE_SIZE = 36;

const routeWillBeCached = () => {
  if (!process.server) {
    return false;
  }

  const currentMatchedRoute = useRequestEvent().context.matchedRoute.path;
  const currentRouteConfig = routeConfig[currentMatchedRoute];
  return Boolean(
    currentRouteConfig && currentRouteConfig?.swr && currentRouteConfig.cache
  );
};

const getLoop54Headers = () => {
  const shouldSendLoop54Cookie = process.client || !routeWillBeCached(); // We don't want to pass User-Id avoid caching personalized results

  const loop54Cookie = useLoop54UserIdCookie();

  return {
    'User-Id':
      shouldSendLoop54Cookie && loop54Cookie.value ? loop54Cookie.value : '',
  };
};

const getAttributeValue = (attributes, name) =>
  attributes?.find((a) => a.name === name)?.values;

export const useSearchStore = defineStore('search', () => {
  const nuxtApp = useNuxtApp();
  const countryConfig = useShopCountryConfig();

  const autoCompleteCategories = ref([]);
  const autoCompleteInput = ref('');
  const autoCompleteQueries = ref([]);
  const commonSearches = ref({});
  const filterTypes = ref({});
  const filters = ref([]);
  const loading = ref(false);
  const loadingCommonSearches = ref(false);
  const loadingFilters = ref(false);
  const page = ref(1);
  const pagePath = ref(null); // Track gtm page view when updated
  const pageSize = ref(DEFAULT_PAGE_SIZE);
  const products = ref(null);
  const relatedProducts = ref(null);
  const resultCount = ref(0);
  const showSearchSuggestions = ref(false);
  const contentCard = ref(null);

  const contentCardConfig = getContentCardConfig(useLocalePath());

  const sortOptions = {
    best_seller: { order: 'desc', type: 'popularity' },
    newest: {
      attributeName: 'date_created',
      order: 'desc',
      type: 'attribute',
    },
    price_asc: {
      attributeName: 'price',
      order: 'asc',
      type: 'attribute',
    },
    price_desc: {
      attributeName: 'price',
      order: 'desc',
      type: 'attribute',
    },
    recommended: null,
  };

  const spellingSuggestions = ref([]);

  const state = {
    autoCompleteCategories,
    autoCompleteInput,
    autoCompleteQueries,
    commonSearches,
    filterTypes,
    filters,
    loading,
    loadingFilters,
    loadingCommonSearches,
    page,
    pagePath,
    pageSize,
    products,
    relatedProducts,
    resultCount,
    showSearchSuggestions,
    sortOptions,
    spellingSuggestions,
    contentCard,
  };

  const locale = computed(() => nuxtApp.$i18n.locale.value);

  const language = useLanguage();

  const getters = {
    locale,
  };

  const autoComplete = async (query) => {
    try {
      const autoCompleteResults = await nuxtApp.$apiFetch(
        '/rest/search/autocomplete',
        {
          method: 'POST',
          headers: getLoop54Headers(),
          body: JSON.stringify({
            customData: {
              categoriesResultsOptions: {
                take: 5,
              },
            },
            queriesOptions: {
              take: 5,
            },
            query,
          }),
        }
      );

      actions.setAutoCompleteInput(query);
      actions.setAutoCompleteQueries(autoCompleteResults?.queries?.items);
      actions.setAutoCompleteCategories(
        autoCompleteResults?.customData?.categoriesFound?.items
      );

      return autoCompleteResults;
    } catch (error) {
      actions.setLoadingCommonSearches(false);
      return error;
    }
  };

  const getCampaignProducts = ({
    queryParams,
    attributeName,
    attributeValue,
    searchFilters,
  }) => {
    const searchParams = Object.fromEntries(new URLSearchParams(searchFilters));
    return actions.getProductsByAttribute({
      attribute: {
        name: attributeName,
        value: attributeValue,
      },
      queryParams: {
        ...queryParams,
        ...searchParams,
        [countryConfig.facets[language].campaign]: 'true',
      },
      hideContentCard: true,
    });
  };

  const getCommonSearches = async () => {
    actions.setLoadingCommonSearches(true);
    try {
      const { data: searchData } = await nuxtApp.$apiFetch(
        '/rest/search/common-searches'
      );

      actions.setCommonSearches({
        locale: getters.locale.value,
        commonSearches: searchData,
      });
      actions.setLoadingCommonSearches(false);

      return searchData;
    } catch (error) {
      actions.setLoadingCommonSearches(false);
      return error;
    }
  };

  const getFilterTypes = async () => {
    // Get available facets in case we don't already have them and we have applied filters
    if (!state.filterTypes.value?.[getters.locale.value]?.length) {
      try {
        actions.setLoadingFilters(true);
        const data = await nuxtApp.$apiFetch(
          '/rest/search/indexed-attributes',
          {
            method: 'GET',
          }
        );
        actions.setFilterTypes({
          filterTypes: data?.attributes || [],
          locale: getters.locale.value,
        });
        actions.setLoadingFilters(false);
      } catch (error) {
        actions.setLoadingFilters(false);
        return error;
      }
    }
    return state.filterTypes.value?.[getters.locale.value];
  };

  const getProducts = async ({
    endpoint,
    queryParams,
    payload,
    resultsOptions,
    hideContentCard,
  }) => {
    actions.setLoading(true);
    actions.setProducts([]);

    try {
      const queryParamPage = +queryParams.page || 1;
      const sort = queryParams?.sort && state.sortOptions[queryParams.sort];
      const { isContentCardsEnabled } = useRuntimeConfig().public.featureFlags;

      const { attribute } = payload;

      // Set content card if we have one for the current attribute
      contentCard.value =
        (isContentCardsEnabled &&
          !hideContentCard &&
          attribute &&
          contentCardConfig?.[getters.locale.value]?.find((card) =>
            card.showForAttributes.find(
              (showForAttribute) =>
                attribute.name === showForAttribute.name &&
                attribute.value.includes(showForAttribute.value)
            )
          )) ||
        null;

      // If we should show the content card, fetch one less product to make room
      if (contentCard.value) {
        actions.setPageSize(DEFAULT_PAGE_SIZE - 1);
      } else {
        actions.setPageSize(DEFAULT_PAGE_SIZE);
      }

      const countryConfigFacets = countryConfig.facets?.[language];

      const responseData = await nuxtApp.$apiFetch(
        `/rest/search${endpoint || ''}`,
        {
          method: 'POST',
          headers: getLoop54Headers(),
          body: JSON.stringify({
            resultsOptions: {
              facets: Object.keys(queryParams).reduce((acc, name) => {
                if (
                  state.filterTypes.value?.[getters.locale.value]?.includes(
                    name
                  )
                ) {
                  return [
                    ...acc,
                    {
                      attributeName:
                        name !== countryConfigFacets.brand ? name : 'brandName',
                      name,
                      selected: queryParams?.[name]?.split('|') || [],
                      type: 'distinct',
                    },
                  ];
                }
                return acc;
              }, []),
              skip: (queryParamPage - 1) * state.pageSize.value,
              sortBy: sort ? [sort] : [],
              take: state.pageSize.value,
              ...resultsOptions,
            },
            ...payload,
          }),
        }
      );

      // Do not show animal types on animal type category listings, or brands on brand listings. Never show campaign filter
      const isAnimalTypeCategory =
        attribute?.name === 'categoriesPath' &&
        attribute?.value?.includes(countryConfig.categories.animalType);

      const isBrandPage = attribute?.name === 'brandPath';

      const facets = responseData?.results?.facets.filter(
        (facet) =>
          !(
            (facet.name === countryConfigFacets.animalType &&
              isAnimalTypeCategory) ||
            (facet.name === countryConfigFacets.brand && isBrandPage) ||
            facet.name === countryConfigFacets.campaign
          )
      );

      actions.setPage(queryParamPage);
      actions.setSpellingSuggestions(responseData?.spellingSuggestions);
      actions.setProducts(responseData?.results?.items);
      actions.setResultCount(responseData?.results?.count);
      actions.setFilters(facets);
      actions.setRelatedProducts(responseData?.relatedResults?.items);
      actions.setPagePath(queryParams.fullPath);
      actions.setLoading(false);

      return responseData;
    } catch (error) {
      console.error(
        'Error fetching Loop54 products. Payload:',
        payload,
        'Error:',
        error
      );
      actions.setLoading(false);
      actions.resetResult();
      return error;
    }
  };

  const getProductsByAttribute = ({
    queryParams,
    attribute,
    hideContentCard,
  }) =>
    actions.getProducts({
      endpoint: '/by-attribute',
      payload: {
        attribute,
      },
      queryParams,
      resultsOptions: {
        filter: {
          and: [
            {
              comparisonMode: 'equals',
              type: 'type',
              value: 'Product',
            },
          ],
        },
      },
      hideContentCard,
    });

  const resetResult = () => {
    actions.setLoading(false);
    actions.setProducts([]);
    actions.setResultCount(0);
    actions.setFilters([]);
    actions.setRelatedProducts([]);
  };

  const search = (queryParams) => {
    if (!queryParams?.q) return null;

    return actions.getProducts({
      payload: {
        query: queryParams.q,
      },
      queryParams,
    });
  };

  const sendLoop54AddToCartEvent = (productId) => {
    actions.sendLoop54Events([
      {
        entity: { id: `${productId}`, type: 'Product' },
        type: 'addtocart',
      },
    ]);
  };

  const sendLoop54ClickEvent = (productId) => {
    actions.sendLoop54Events([
      {
        entity: { id: `${productId}`, type: 'Product' },
        type: 'click',
      },
    ]);
  };

  const sendLoop54Events = async (events) => {
    try {
      const data = await nuxtApp.$apiFetch('/rest/search/events', {
        method: 'POST',
        headers: getLoop54Headers(),
        body: { events },
      });

      return data;
    } catch (error) {
      return error;
    }
  };

  const sendLoop54PurchaseEvent = ({
    products: purchasedProducts,
    orderId,
  }) => {
    const events = purchasedProducts.map(({ productId, price, quantity }) => ({
      entity: {
        id: `${productId}`,
        type: 'Product',
      },
      orderId,
      quantity,
      revenue: price,
      type: 'purchase',
    }));
    actions.sendLoop54Events(events);
  };

  const actions = {
    clearAutoComplete() {
      state.autoCompleteQueries.value = [];
      state.autoCompleteCategories.value = [];
    },
    setAutoCompleteCategories(autoCompleteCategoriesValue) {
      state.autoCompleteCategories.value =
        autoCompleteCategoriesValue?.map((ac) => {
          const { id, attributes } = ac;
          return {
            id: +id,
            longName: getAttributeValue(attributes, 'categoriesLongName') || [],
            name: getAttributeValue(attributes, 'categoriesName')?.[0],
            path: getAttributeValue(attributes, 'categoriesPath')?.[0],
          };
        }) || [];
    },
    setAutoCompleteInput(autoCompleteInputValue) {
      state.autoCompleteInput.value = autoCompleteInputValue;
    },
    setAutoCompleteQueries(autoCompleteQueriesValue) {
      const acc =
        autoCompleteQueriesValue.map((ac) => capitalize(ac?.query)) || [];
      state.autoCompleteQueries.value = acc;
    },
    setCommonSearches({
      locale: localeValue,
      commonSearches: commonSearchesValue,
    }) {
      state.commonSearches.value[localeValue] = commonSearchesValue;
    },
    setFilterTypes({ locale: localeValue, filterTypes: filterTypesValue }) {
      state.filterTypes.value[localeValue] = filterTypesValue;
    },
    setFilters(filtersValue) {
      state.filters.value = filtersValue;
    },
    setLoading(loadingValue) {
      state.loading.value = loadingValue;
    },
    setLoadingCommonSearches(loadingCommonSearchesValue) {
      state.loadingCommonSearches.value = loadingCommonSearchesValue;
    },
    setLoadingFilters(loadingValue) {
      state.loadingFilters.value = loadingValue;
    },
    setPage(pageValue) {
      state.page.value = pageValue;
    },
    setPagePath(pagePathValue) {
      state.pagePath.value = pagePathValue;
    },
    setPageSize(pageSizeValue) {
      state.pageSize.value = pageSizeValue;
    },
    setProducts(productsValue) {
      state.products.value = productsValue;
    },
    setRelatedProducts(relatedProductsValue) {
      state.relatedProducts.value = relatedProductsValue;
    },
    setResultCount(resultCountValue) {
      state.resultCount.value = resultCountValue || 0;
    },
    setShowSearchSuggestions(showSearchSuggestionsValue) {
      state.showSearchSuggestions.value = showSearchSuggestionsValue;
    },
    setSpellingSuggestions(spellingSuggestionsValue) {
      state.spellingSuggestions.value = spellingSuggestionsValue;
    },
    autoComplete,
    getCampaignProducts,
    getCommonSearches,
    getFilterTypes,
    getProducts,
    getProductsByAttribute,
    resetResult,
    search,
    sendLoop54AddToCartEvent,
    sendLoop54ClickEvent,
    sendLoop54Events,
    sendLoop54PurchaseEvent,
  };

  return {
    ...state,
    ...getters,
    ...actions,
  };
});
